@import "../../../scss/variables";

.container {
  padding: 1.5rem 3rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.formGroup {
  display: flex;
  align-items: center;
}

.errorField {
  outline-color: $error-red !important;
}

.input {
  width: 13rem;
}

.labelsText {
  width: 3.75rem;
  margin: 0 0.75rem 0 0;
  text-align: right;
  font-weight: 400;
  font-size: 0.875rem;
  color: $black-grey
}

.cantRememberText {
  margin: 0 0 0 0.625rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 140.62%;
  color: $teal;
  cursor: pointer;
}

.separator {
  width: 100%;
  height: 0.5px;
  background-color: $weak-grey;
  margin-block: 1.5rem;
}
