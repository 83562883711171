.tooltip {
  pointer-events: none;

  .tooltip-inner {
    background: #333333;
    mix-blend-mode: normal;
    pointer-events: none;
    border-radius: 5px;
    max-width: none;

    font-family: Archivo, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0659179px;
    color: #FFFFFF;

  }

  .arrow {
    display: none;
  }

}
