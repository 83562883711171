@import "../../variables";

$container-height: 34.5rem;

.mapping-config-menu {


  .mapping-config-content {
    padding: 32px 32px 0 32px;

    h5 {
      font-weight: 600;
      font-size: 24px;
    }

    p {
      color: $black;
    }

    div.table-header {
      background-color: $gray-100;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      padding: 7px 14px 7px 14px;
      color: $black;
      text-transform: uppercase;
      font-size: 12px;

      span:last-child {
        float: right;
      }
    }

    .mapping-fields-container {
      height: $container-height;
      scroll-behavior: smooth;
      overflow-y: auto;
      overflow-x: hidden;

      .connection-match-row {
        padding-top: 22px;
        padding-bottom: 22px;

        &:not(:first-child) {
          border-top: 1px solid $gray-200;
        }

        .row-index {
          font-size: 14px;
          width: 25px;
          height: 25px;
          border: 1px solid $black;

          &.is-required {
            background-color: $black;
            color: $white;

            &.is-ignored {
              background-color: $message-gray;
              border-color: $message-gray;
            }
          }

          &.is-not-required {
            background-color: $white;

            &.is-ignored {
              color: $message-gray;
              border-color: $message-gray;
            }
          }
        }

        .connection-info {
          span:first-child {
            font-weight: 600;
          }

          span:last-child {
            font-size: 14px;
            //color: $gray-600;
          }
        }

        .limit-text-half {
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

    }
  }

  .footer {
    padding: 24px 32px 25px 32px;
    border-top: 1px solid $gray-200;
  }
}

.is-ignored {
  color: $message-gray
}

.ignore-icon {
  color: $brand-purple
}

//temporary class to be removed after we implement the new buttons from figma
.black-text-button {
  color: $black !important;
}
