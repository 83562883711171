@import "../../scss/variables";

.grayTableHeader {
  background-color: $faded-grey;
  border-bottom: 0.5px solid $disabled-gray;
  border-top: 0.5px solid $disabled-gray;
}

.grayTableCell {
  color: $standard-grey;
  display: inline-flex;;
  justify-content: left;
  border-bottom: 0 !important;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 140.62%;
}
