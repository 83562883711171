@import "../variables.scss";

.table-responsive {
  overflow-x: visible;
}

.table {
  color: #808080;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;

  &.products-list {

    & > thead {
      background-color: #ffffff;
      border-top: 1px solid $vuuhGray;
      border-bottom: 1px solid $vuuhGray;
      text-align: center;
      font-size: 10.5854px;
      font-weight: 600;
    }

    & > tbody {
      & > tr {
        border-top: 1px solid $vuuhGray;
        border-bottom: 1px solid $vuuhGray;

        &.active {
          background-color: #ffffff;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
          z-index: 100;

          &.active-row {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          &.active-full-view {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }

        & > td {
          vertical-align: middle;
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }

    .single-color-widget {
      color: $vuuhGray;
      border-radius: 30px;
      border: 1px solid $vuuhGray;
      min-width: 100%;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
    }

    .multiple-color-widget {
      color: $vuuhLightGreen;
      border-radius: 30px;
      border: 1px solid $vuuhLightGreen;
      min-width: 100%;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
    }

    .expand-button {
      background-color: white;
      border-radius: 6px;
      height: 15px;
      width: 30px;
      position: relative;

      svg {
        position: absolute;
        top: 5px;
        left: 11.41px;
      }


      &:active, &:focus {
        background-color: white;
        color: $vuuhDarkGray;
      }
    }
  }
}

.color-viewer-popover {
  border-radius: 17px;
  padding: 20px 10px;
  margin-left: 30px;

  .badge {
    border: 2px solid transparent;

    &:hover {
      border: 2px solid #dbb3ff;
      cursor: pointer;
    }


    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.0659179px;
    color: #808080;
    background: #ECDEFF;
    border-radius: 12.5px;
    padding: 6px;
    margin: 5px;
  }
}

.product-thumbnail {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 64px;
  width: 64px;
  border-radius: 4px;
}
