@import "../scss/variables";

.bgRing {
  position: relative;
  border-radius: 50%;
  border-style: solid;

  &Complete {
    border-color: $softer-green;
  }

  &Incomplete {
    border-color: $alternate-yellow-a;
  }

  &Purple {
    border-color: rgba($purple, 0.2);
  }

  &Unknown {
    border-color: $weak-grey;
  }
}

.svgContainer {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
  transform-origin: center;
}

.svg {
  position: absolute;
}

.progressRing {
  fill: transparent;
  stroke-linecap: round;

  &Complete {
    stroke: $good-green;
  }

  &Incomplete {
    stroke: $warning-yellow;
  }

  &Purple {
    stroke: $alternative-purple-a;
  }

  &Unknown {
    stroke: $message-gray;
  }
}

.childrenContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressText {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $standard-grey;
  font-size: 0.625rem;
}

.icon {
  color: $standard-grey;
  font-weight: 300 !important;
  font-size: 1.375rem;
}
