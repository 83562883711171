// Here you can add other styles
@import "../ui-kit/src/scss/variables";

.tree, .tree ul {
  margin: 0;
  padding: 0;
  list-style: none
}

.tree ul {
  margin-left: 1em;
  position: relative
}

.tree ul ul {
  margin-left: .5em
}

.tree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid
}

.tree li {
  margin: 0;
  padding: 0 1em;
  line-height: 2em;
  color: $dark;
  font-weight: 700;
  position: relative
}

.tree ul li:before {
  content: "";
  display: block;
  width: 10px;
  height: 0;
  border-top: 1px solid;
  margin-top: -1px;
  position: absolute;
  top: 1em;
  left: 0
}

.tree ul li:last-child:before {
  background: #ffffff;
  height: auto;
  top: 1em;
  bottom: 0
}

.indicator {
  margin-right: 5px;
}

.tree li a {
  text-decoration: none;
  color: #369;
}

.tree-item-selected {
  display: block;
  background: $primary;
  color: $light;
}

.tree span {
  cursor: pointer;
}

.tree li button, .tree li button:active, .tree li button:focus {
  text-decoration: none;
  color: #369;
  border: none;
  background: transparent;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  outline: 0;
}

.product-template-list-item {
  padding: 10px;
  cursor: pointer;
  height: 48px;
}

.product-template-list-item-selected {
  padding: 10px;
  cursor: pointer;
  height: 48px;
  background: rgba(200, 206, 211, 0.34);
  border-color: #c8ced3;
}

.product-template-list-item:hover {
  background: rgba(200, 206, 211, 0.34);
  border-color: #c8ced3;
}

.app-header {
  background-color: $primary
}

.sidebar {
  background: white;
  color: green;
}

.sidebar .sidebar-nav {
  -webkit-box-shadow: 3px 0 5px 0 rgba(224, 224, 224, 1);
  -moz-box-shadow: 3px 0 5px 0 rgba(224, 224, 224, 1);
  box-shadow: 3px 0 5px 0 rgba(224, 224, 224, 1);
}

.sidebar .nav-link {
  color: #808080;
}

.sidebar .nav-link.active {
  background: transparent;
  color: $primary;
}

.sidebar .nav-link.active:hover {
  background: $primary;
  color: $white;
}

.sidebar .nav-link.active:hover .nav-icon {
  color: $white;
}

.navbar-nav .nav-link {
  color: $white;

  &:hover {
    color: $gray-200;
  }

  &:focus {
    color: $gray-200;
  }
}

body {
  background: #E5E5E5;
}

.sidebar .nav-title {
  color: #808080;
}

.sidebar .sidebar-minimizer {
  background: white;
}

.sidebar-minimized .sidebar .sidebar-minimizer {
  background: white;
}

.app-header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='white' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>")
}

.breadcrumb {
  border-bottom: none;
}

.file-upload-modal-icon {
  font-size: xx-large;
  color: #8235FF;
}

.file-upload-modal-progress-bar {
  margin-top: -16px;
}

.file-upload-modal-file-label {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
}

.card-title {
  margin: 20px 20px 0;
  font-family: "Archivo Black", sans-serif;
  font-size: 22px;
  font-weight: bold;
}

.card-body {
  padding-top: 12px;
  padding-bottom: 12px;
}

.card-title h3 {
  font-weight: bold;
  font-size: 1.5rem;
  font-family: "Archivo Black", sans-serif;
}

.table th, .table td {
  border-top: none;
}

.table td, .table th {
  border-top: none;
}

.distribute-data-template-arrow {
  width: 88%;
  height: 3px;
  float: left;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+2,000000+4,8234ff+100&1+0,1+0,0.8+0,0.8+100 */
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 2%, rgba(0, 0, 0, 0.8) 4%, rgba(130, 52, 255, 0.8) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 2%, rgba(0, 0, 0, 0.8) 4%, rgba(130, 52, 255, 0.8) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 2%, rgba(0, 0, 0, 0.8) 4%, rgba(130, 52, 255, 0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ccffffff', endColorstr='#cc8234ff', GradientType=1); /* IE6-9 */
  margin-top: 15px;
  position: relative;
  z-index: 14;
}

.card-shadow {
  -webkit-box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.10);
  box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.10);
}

.card-footer {
  background: $white;
}

.timeline-item {
  background: #fff;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 10px;
  padding: 2px;
  margin: 0 auto;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 25px;
  position: relative;
  border-radius: 10px;
}

.background-masker {
  background: #fff;
  position: absolute;
}

/* Every thing below this is just positioning */

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: 40px;
  right: 0;
  height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 40px;
  height: 8px;
  width: 10px;
}

.background-masker.header-bottom {
  top: 18px;
  height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 6px;
}


.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
}

.background-masker.subheader-right {
  left: 230px;
}

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px;
}

.background-masker.content-top {
  height: 20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px;
}

.background-masker.content-second-line {
  top: 68px;
}

.background-masker.content-second-end {
  left: 420px;
  top: 74px;
}

.background-masker.content-third-line {
  top: 82px;
}

.background-masker.content-third-end {
  left: 300px;
  top: 88px;
}

.schema-row {

  &:hover {
    background-color: $gray-100;
    border-radius: 5px;
  }

}

.schema-field-description {
  max-width: 135px;
  display: inline-flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input-group-button {
  border: 1px solid $idle-gray;
  color: black;
  height: 32px;
}

.filepond--action-process-item {
  visibility: hidden;
}


.jsoneditor-outer.has-status-bar {
  margin: -35px 0 -26px 0;
  padding: 35px 0 26px 0;
  height: 600px;
}

@import "./toastify";
