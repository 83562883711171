// overwrite some Toastify colors to better fit our theme
// by default, it comes with some already implemented colors
// that are too bright for our app
@import "../ui-kit/src/scss/variables";

.Toastify__toast--success {
  background: $success;
}

.Toastify__toast--error {
  background: $danger;
}

.Toastify__toast {
  background: $white;
  font-family: 'Lato', serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 140.62%;
  color: $black-grey;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding-inline: 0.75rem;
}