@import "../scss/variables";

.notificationLine {
  height: 2.875rem;
  width: 0.375rem;
  border-radius: 8px;
  margin-right: 0.75rem;
}

.successLine {
  background: $success;
}

.warningLine {
  background: $warning-yellow;
}

.infoLine {
  background: $black-grey;
}

.errorLine {
  background: $danger;
}

.neutralLine {
  background: $standard-grey;
}

.title {
  font-family: 'Lato', serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: $black-grey;
  margin: 0;
}

.message {
  font-family: 'Lato', serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: $black-grey;
  margin: 0;
}
