// these are the variables from figma
// https://www.figma.com/file/Ue3VPWGH0Y5NjvXmPU0Jwt/Library?node-id=73%3A30

$brand-purple: #8235FF;
$purple: #8235FF;
$faded-purple: #F8F2FF;
$purple-shade: #F3EBFF;
$purple-weak: #D8C1FF;
$purple-gradient: linear-gradient(126.47deg, #8235FF 31.25%, #F54BE4 100%);
$white: #FFFFFF;
$black: #333333;
//$black-grey: #231F35;
$black-grey: #4F4F4F;
$standard-grey: #4F4F4F;
$active-grey: #333333;
$dark-grey: #808080;
$medium-grey: #C8C8C8;
$light-grey: #F2F2F2;
$faded-grey: #F8F8F8;
$weak-grey: #E0E0E0;
$green: #4ADA95;
$soft-green: #D4EFE0;
$softer-green: #7DCEA0;
$alternate-yellow-a: #F7DF94;
$alternate-yellow-b: #FBECC1;
$alternate-yellow-c: #FFF6DD;
$amazon-orange: #FF9900;
$amazon-orange-darker: #f19100;
$bol-com-blue: #00f;
$bol-com-blue-darker: #3c3eff;

// Variable overrides


// some badly named alaises we used at first, these should be swapped out at some point
$vuuhLightGreen: $green;
$vuuhGray: $medium-grey;
$vuuhDarkGray: $dark-grey;


$sidebar-width: 180px;


// figma variables (Vasil's Figma version)
$primary-gray: $black;
$secondary-gray: $black-grey;
$idle-gray: #828282;
$message-gray: #BDBDBD;
$disabled-gray: #E0E0E0;
// alias gray-grey, not sure what is correct
// but until now we have used both variants in the project. Woopsie
$disabled-grey: $disabled-gray;
$accent-gray: $light-grey;

$accent-purple: #E6D7FF;
$accent-purple-2: #F8F2FF;

// figma naming
$error-red: #EB5757;
$warning-yellow: #F2C94C;
$good-green: #27AE60;

$alternative-red-a: #F18989;
$alternative-red-b: #F4A4A4;
$alternative-red-c: #F9CDCD;

$alternative-purple-a: #BA8FFF;
$alternative-purple-b: #C8A6FF;
$compliment-purple: #9554FF;

// overwrite bootstrap variables
$purple: $brand-purple;
$teal: #8235FF;

$primary: $purple;
$primary-light: $purple-shade;
$secondary: $white;
$danger: $error-red;
$warning: $warning-yellow;
$success: $good-green;

$btn-focus-box-shadow: false;
$btn-active-box-shadow: false;
$btn-border-width: 0;
$btn-border-radius: 8px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-md: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;

$btn-padding-x: 1.25rem;
$btn-padding-y: 0.5rem;
$btn-line-height: 1.25;
$btn-font-size: 16px;

$btn-padding-x-sm: 0.85rem;
$btn-padding-y-sm: 0.42rem;
$btn-line-height-sm: 1.42;
$btn-font-size-sm: 14px;

$btn-primary-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
$btn-secondary-box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);

$dropdown-box-shadow: 0.5px 0.5px 6px rgba(0, 0, 0, 0.25);
$dropdown-border-radius: 8px;

$enable-shadows: true;
$border-radius: 0.7rem;

$font-family-sans-serif: 'Lato', sans-serif;
$font-size-base: 1rem;

$card-border-radius: 4px;
$card-border-width: 0;
$card-cap-bg: $white;

$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-focus-border-color: $idle-gray;
$input-border-radius: 8px;
// others

$primary-purple: $primary;


$generic-box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.10);

//Products page breakpoints

$columns-3: 1700;
$columns-2: 1550;
$columns-1: 1350;

:export {
  columns-3: $columns-3;
  columns-2: $columns-2;
  columns-1: $columns-1;
}
