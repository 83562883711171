@import '../scss/variables';

.removeTokenIcon {
  background-color: $danger;
  color: $white;
  font-size: 10px;
  padding: 4px;
  position: absolute;
  top: -9px;
  right: -9px;
  border-radius: 50%;
}
