@import "../ui-kit/src/scss/variables";

$coreui-icons-font-path: "~@coreui/icons/fonts";
@import "~@coreui/icons/scss/free/free-icons";
@import '~flag-icon-css/sass/flag-icon';
@import '~simple-line-icons/scss/simple-line-icons';
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";


@import "~@coreui/coreui/scss/coreui.scss";
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

@import "../ui-kit/src/scss/style.scss";

@import "custom";
@import "components/schema-path-selection";
@import "components/product-schema-selection";
@import "components/tabs";
@import "components/unique-values";
@import "components/data-points-connections-table";
