@import "../variables";

.modal-open {
  .app-body, .app-header {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }

}

.modal {
  .modal-content {
    border: 0;
    box-shadow: 0 0;
    border-radius: 10px;
  }


  .modal-content:not(.gray-modal) {
    padding: 0.75rem 0.75rem 0;

    .row:last-child {

      .col:first-child {
        padding-right: 5px;
      }

      .col:last-child {
        padding-left: 5px;
      }
    }
  }

  h6 {
    margin-top: 1rem;
    font-weight: 600;
  }

  p.text-muted {
    font-size: 14px;
  }
}


