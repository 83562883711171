@import "../variables.scss";




input.form-control:not([type='checkbox']):not(.custom-form-group) {

  &:not([type='checkbox']), &:not(.custom-control) {
    background: $white;
    border: 1px solid $idle-gray;
    font-size: 14px;
    line-height: 20px;
    height: 32px;
    padding: 6px 12px 6px 12px;
    color: $black;

    &:focus {
      border: 1px solid $secondary-gray;
      box-sizing: border-box;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    }

    ::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0659179px;
      text-align: center;

      color: $idle-gray;
    }

    &:hover {
      border-color: $black-grey;
    }

    &:disabled {
      background-color: $disabled-gray;
      color: white;
      border: 1px solid $disabled-gray;
      box-shadow: none;

      &::placeholder {
        color: white;
      }
    }
  }

}

.custom-control.custom-checkbox {
  transition: all 0.25s ease-out;

  .custom-control-label {

    &.disabled {
      &::before {
        border-color: $disabled-gray;
      }
    }

    &.checkbox-size-sm {
      &::before {
        height: 14px;
        width: 14px;
      }

      &::after {
        height: 14px;
        width: 14px;
      }
    }

    &::before {
      border-radius: 2px;
      border-width: 2px;
      border-color: $secondary-gray;
      height: 18px;
      width: 18px;
    }

    &::after {
      height: 18px;
      width: 18px;
    }
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $primary;
}

.alternate-indeterminate::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23333333' d='M0 2h4'/%3e%3c/svg%3e");
}

.boxed-input {
  & label {
    color: #999999;
    font-family: Archivo, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    background-color: white;
    padding: 2px;
    left: 17px;
    top: -10px;
  }

  input.form-control {
    // border and spacing
    border: 2px solid #E5E5E5 !important;
    border-radius: 2px !important;
    padding: 17px;

    // font
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 0.248889px;
    color: #0A0033;

    &:focus {
      border: 0;
    }
  }
}
