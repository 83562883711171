@import "../variables";

.card-body {
  padding-top: 24px;
  padding-bottom: 12px;
}

.card-header {
  background-color: $white;
  border-bottom: 0.5px solid $disabled-gray;
  font-weight: 600;
  padding: 20px 24px;

  & > h4 {
    font-family: $font-family-sans-serif;
    font-size: 24px;
    line-height: 33.75px;
    vertical-align: middle;
    letter-spacing: 0.3px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.card {
  &.card-shadow {
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
  }
}
