@import "../variables";

.quick-action {

  button {
    color: #808080;
    background-color: transparent;
    border: 0;
    box-shadow: 0 0;

    &:hover {
      color: $primary;
      background-color: transparent;
    }

    &:focus {
      color: $primary;
      background-color: transparent;
    }
  }
}

.btn {
  font-weight: 600;
  letter-spacing: 0.2px;
  font-style: normal;

  &.btn-primary {
    box-shadow: $btn-primary-box-shadow;

    &:hover {
      background-color: $secondary-gray;
    }

    &:focus {
      background-color: $primary-gray;

      &:active {
        background-color: $primary-gray;
      }

      &:active:focus {
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
      }
    }

    &:disabled {
      background-color: $disabled-grey;
      color: $white;
      box-shadow: none;
    }
  }

  &.btn-secondary {
    box-shadow: $btn-secondary-box-shadow;
    color: $primary;

    &:hover {
      background-color: $secondary-gray;
      color: $white;
    }

    &:focus {
      background-color: $primary-gray;
      color: $white;

      &:active {
        background-color: $primary-gray;
        color: $white;
      }

      &:active:focus {
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
        color: $white;
      }
    }

    &:disabled {
      background-color: $disabled-grey;
      color: $white;
      box-shadow: none;
    }
  }

  &:active {
    background-color: $black;
    border-color: $black;
  }

  &:disabled {
    background-color: $disabled-grey;
    color: $secondary;
    border-color: $light-grey;
  }

  img {
    padding-right: 10px;
    width: 40px;
  }

  &.text-btn {
    font-weight: 600;
    text-decoration-line: underline;
    line-height: 19px;
    letter-spacing: 0.08px;
    box-shadow: none;
    border: none;

    &:hover:enabled {
      background-color: transparent;
      border: none;
      color: $black-grey;
    }
  }
}

