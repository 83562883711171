.registration-modal {
  font-family: Lato, sans-serif;
  color: $black;

  .modal-content {
    padding: 0;
    border-radius: 8px;
    min-width: 520px;

    .error-alert{
      color:$error-red;
    }

    .registration-modal-form-section {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 8px;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        width: 150%;
        height: 99%;
        background: $accent-purple;
        border-radius: 70%;
        transform-origin: bottom;
        transform: translateX(-50%) scale(2);
        border-bottom: 2px solid $alternative-purple-b;
      }

      .registration-modal-form-content {
        position: relative;
        z-index: 1;
        margin: 0 auto;
        max-width: 85%;
        text-align: center;
        font-size: 16px;
        line-height: 28px;
        padding-bottom: 10px;

        .modal-inner-icon-background {
          background-color: $alternative-purple-a;
          border-radius: 50%;
          width: 44px;
          height: 44px;
        }

        .modal-outer-icon-background {
          background-color: $alternative-purple-b;
          border-radius: 50%;
          width: 56px;
          height: 56px;
        }

        u {
          color: $teal;
        }

        Input {
          width: 202px;
          height: 32px;
        }

        .modal-icon {
          color: white;
          width: 27px;
        }
      }

      .modal-header {
        border-bottom: none;
        justify-content: center;
        padding-bottom: 10px;
      }
    }

    .modal-footer {
      border-top: none;

      div {
        width: 100%;
        justify-content: center;
      }

      .button-wrapper {
        padding-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

