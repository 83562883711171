@import "../scss/variables";

$modal-border-radius: 20px;

.bodyBackground {
  background-color: $light-grey;
  border-radius: 0 0 $modal-border-radius $modal-border-radius;
}

.bodyOnlyBackground {
  background-color: $light-grey;
  border-radius: $modal-border-radius;
}

.container {
  border-radius: $modal-border-radius;

  :global .modal-content.gray-modal {
    border-radius: $modal-border-radius;
  }
}

.header {
  border-bottom: 0.5px solid $weak-grey;
  border-top-left-radius: $modal-border-radius;
  border-top-right-radius: $modal-border-radius;
}

.progressAnimation {
  circle {
    transition: stroke-dashoffset 1.2s ease-in-out;
  }
}

.stepIndicator {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $standard-grey;
  margin: 0;
}

.roadmapTextContainer {
  margin-left: 0.75rem;
}

.title {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2rem;
  margin: 0;
  color: $standard-grey;
}

.stepTitle {
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0;
  color: $standard-grey;
}

.stepText {
  font-size: 1.75rem;
  line-height: 2rem;
  margin: 0.125rem 0 0 0;
  color: $standard-grey;
}

