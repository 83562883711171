@import "../variables";
.progress {
  height: 4px;
}


@keyframes loader-animation {
  0% {
    left: -100%;
  }
  49% {
    left: 100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

// Usage:
// <div class="line-loader"><div class="bar"></div></div>
.line-loader {
  height: 5px;
  width: 100%;
  overflow: hidden;

  .bar {
    width: 100%;
    position: absolute;
    height: 5px;
    background-color: $primary;
    animation-name: loader-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}


