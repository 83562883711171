.popover {
  min-width: 500px;

  .schema-path-selection {

    .object-container {
      border-radius: 0.5rem;
      border-left: 1px solid #d5d5d5;
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }

    ul {
      margin-left: 2rem;

      .value {
        margin-left: 1rem;
      }
    }

    .title {
      text-decoration: underline;
    }

    .title:hover:has(.value.highlight) {
      background-color: #dffdc2;
    }

    .value, .title {
      width: 100%;
      padding: 3px 7px 3px 7px;
      border-radius: 3px;
      cursor: pointer;


      &.checked {
        font-weight: 600;
      }

      &.candidate {
        background-color: #ecfddd;

        &:hover {
          background-color: #dffdc2;
        }
      }

      &:not(.candidate) {
        cursor: default;
        color: #c8c8c8;
      }

      &.highlight {
        font-weight: 600;
      }

      &:hover {
        background-color: #e5e5e5;
      }
    }
  }
}

