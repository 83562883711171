
.nav.nav-tabs {
  .nav-link {
    font-weight: 400;

    &.active {
      background-color: transparent;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 3px solid $primary;
      color: $primary;
    }
  }
}
