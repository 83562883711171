@import "../scss/variables";

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border-radius: 10px;
}

.shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
}

.border {
  border: 0.5px solid $weak-grey;
}

.fillSpace {
  height: 100%;
}

.simpleHeader {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  min-height: 5rem;
  border-bottom: 0.5px solid $weak-grey;
}


.coloredHeaderBand {
  border-radius: 8px 8px 0 0;
  padding-left: 1.5rem;
  height: 2rem;

  line-height: 2rem;
  font-weight: 600;
  font-size: 0.875rem;
}

.headerActions {
  & > * { // all direct children get spaced
    margin-left: 1rem;
  }
}


.cardBody {
  min-height: calc(100vh - 14rem);
  padding: 1.5rem;
}


.separator {
  height: 1.25rem;
  width: 0.0625rem;
  background-color: $weak-grey;
}
