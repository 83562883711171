.react-dropdown-tree-select.bootstrap-demo {
  .dropdown {
    width: 100%;
  }

  a.dropdown-trigger {
    border: 0;
    border-radius: 0.25rem;
  }

  .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555;
  }

  .toggle {
    font: normal normal normal 12px/1 FontAwesome;
    color: #555;
  }

  .toggle.collapsed::after {
    content: "+";
  }

  .toggle.expanded::after {
    content: "-";
  }

  .root {
    padding: 0;
    margin: 0;
  }


  .dropdown-content {
    width: 100%;
    max-height: 30rem;
    overflow-y: auto;

    .node {
      margin: 3px 0 3px 0;
      border-radius: 5px;
      &.compatible {
        background-color: #d2efd8;
      }

      &.incompatible {
        background-color: #f3dedb;
      }
    }

  }
}
