@import "../../scss/variables";

.productImagePlaceholder {
  border-radius: 0.5rem;
  background-color: transparent;
  outline: 0.5px solid $message-gray;

  i {
    color: $standard-grey;
  }
}

.sm {
  height: 48px;
  width: 48px;
}

.md {
  height: 56px;
  width: 56px;
}

.mdLg {
  height: 82px;
  width: 82px;
}

.lg {
  height: 137px;
  width: 137px;
}

.xl {
  height: 174px;
  width: 174px;
}
