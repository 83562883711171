@import "../scss/variables";

.modalAlert {
  display: flex;
  align-items: baseline;
  color: $error-red;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.exclamationIcon {
  font-weight: 300;
  margin-right: 0.375rem;
}
