@import "../scss/variables";

.input {
  background: $white;
  color: $standard-grey;
  font-size: 0.75rem;
  box-sizing: border-box;
  outline: 0.5px solid $weak-grey;
  border: none;
  border-radius: 10px;
  height: 2rem;
  width: 100%;
  padding: 0.5rem 0.75rem;
  transition: all 0.25s ease-out;

  &::placeholder {
    color: $message-gray;
  }

  &:hover:not(:active):not(:focus):not(:disabled) {
    background-color: $faded-grey;
  }

  &:active, &:focus {
    outline: 1px solid $purple-weak;
  }

  &:active + i, &:focus + i {
    color: $black-grey
  }
}

.disabled {
  background: $weak-grey;
  outline: 0.5px solid $message-gray;
  cursor: not-allowed;

  & + i {
    color: $disabled-gray;
  }

  &::placeholder {
    color: $disabled-gray
  }

  &:active, &:focus {
    outline: 0.5px solid $disabled-gray;
    box-shadow: none;
  }

  &:active + i, &:focus + i {
    color: $disabled-gray
  }
}

.invalid {
  outline: 0.5px solid $error-red;
  box-shadow: none;

  &:active, &:focus {
    outline: 0.5px solid $error-red;
    box-shadow: none;
  }
}

.searchInputContainer {
  width: 100%;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  position: relative;
  // Remove input default decoration
  input[type=search]::-ms-clear { display: none; width : 0; height: 0; outline-offset: 0; }
  input[type=search]::-ms-reveal { display: none; width : 0; height: 0; outline-offset: 0; }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

  input {
    padding-left: 2rem;
    padding-right: 2.125rem;
  }

  input:focus {
    outline: 1px solid $alternative-purple-a;
  }

  input::placeholder {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: $message-gray;
  }
}

.searchInput {
  outline: 0.5px solid $disabled-gray;
  outline-offset: 0;
}

.searchIcon {
  position: absolute;
  left: 0.875rem;
  color: $idle-gray;
  font-weight: 400 !important;
  font-size: 0.75rem;
  line-height: 1rem !important;
}

.clearIcon {
  color: $alternative-purple-a;
  position: absolute;
  right: 0.5rem;
  top: 1rem;
}

.textarea {
  height: auto;
  outline: 1px solid $weak-grey;
}
