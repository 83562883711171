@import "../variables.scss";

.single-product-view {

  padding: 3rem;

  img {
    border-radius: 16px;
  }

  label {
    color: $vuuhDarkGray;
    font-weight: bold;
    font-size: 14px;
  }

  // use !important on all properties so that these are not overwritten by custom
  // input styling from other more specific/general components.
  input.form-control {
    background-color: transparent !important;
    border-radius: 0 !important;
    border-bottom: 0.5px solid #979797 !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    box-shadow: 0 0 !important;

    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.0769042px !important;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0769042px;
  }

  h6 {
    font-size: 16px;
    font-weight: 600;
  }

  .badge {
    padding: 7.5px;
    margin-left: 5px;
    background: #E5E5E5;
    border-radius: 12.5px;
    line-height: 13px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.0659179px;
    font-size: 12px;
    color: #0A0033;
  }

  .color-dot {
    height: 3rem;
    width: 3rem;
    border-radius: 3rem;
  }

  .nav-tabs {
    .nav-link {
      background: transparent;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-bottom: $vuuhGray;
      margin: 0 10px 0 10px;


      &.active {
        border-bottom: 2px solid $primary;
        color: $primary;
      }
    }
  }

  .tab-content {
    background: transparent;
    border: 0;
  }

  .table {
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    background-color: white;

    thead {
      border-bottom: 0;
    }

    td {
      padding: 1rem;
    }
  }

  .product-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 230px;
    width: 230px;
    border-radius: 20px;
  }

  .image-nav-button {
    color: #808080;

    &:hover {
      color: #676767;
    }

    &:focus {
      box-shadow: 0 0 !important;
    }

    &:disabled {
      color: transparent;
    }
  }

}


