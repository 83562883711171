.unique-values-table {

  .unique-value-selector {

    margin: 3px;
    position: relative;
    min-height: 100%;
    text-align: center;

    &.hidden {
      opacity: 0;
      cursor: auto;
    }

    &:hover {
      background-color: $gray-200;
    }

    &.active {
      background-color: $primary-light;
    }

    &:not(.has-value) {
      background-color: $gray-100;

      &:hover {
        background-color: $gray-200;
      }

      &.active {
        background-color: $primary-light;
      }
    }

  }
}