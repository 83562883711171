.data-point-connections-table {

  tr {
    transition: all .25s ease-out;
    border-radius: 1rem;

    &.being-dragged {
      background-color: $primary-light;
      opacity: 25%;
    }

    &.is-required {
      background-color: $primary-light;
    }
  }
}