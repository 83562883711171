@import "../variables";

.upload-collection {
  h6 {
    color: #231F35;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.0988769px;
  }

  .list-group {
    .list-group-item {
      border-left: 0;
      border-right: 0;
      border-top: 1px solid #EDEDF2;
      border-radius: 0;

      small {

        &.file-name, &.file-size {
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
        }

        &.progress-text {
          font-size: 10px;
        }
      }

      &:first-child {
        border-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}


.collection-info-modal {
  min-width: 660px;

  .modal-body {
    padding: 30px;
  }
}
