@import "../../../../../ui-kit/src/scss/variables";

.row {
  display: flex;
  height: 5.75rem;
  border-bottom: 0.5px solid $disabled-gray;
}

.image {
  display: flex;
  justify-content: center;
  width: 4.25rem;
  height: 4.25rem;
  filter: drop-shadow(0.5px 0.5px 3px rgba(0, 0, 0, 0.25));
  border-radius: 0.5rem;
  margin-inline: 1.5rem;
  background-color: transparent;
  outline: 0.5px solid $message-gray;
  overflow: hidden;

  & > img {
    height: 100%;
    margin: 0 auto;
  }
}