@import '../scss/variables';

.pill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  border-radius: 1.25rem;
  border: none;
}

.primary {
  background: $purple;
  color: $white;
  transition: all 0.25s ease-out;

  &:hover {
    background: $compliment-purple;
  }

  &:active, &:focus, &.active {
    background: $alternative-purple-a;
    outline: none;
  }
}

.secondary {
  background: $white;
  color: $standard-grey;
  outline: 0.5px solid $weak-grey;
  transition: all 0.25s ease-out;

  &:hover {
    outline: 0.5px solid $weak-grey;
    background: $faded-grey;
  }

  &:active, &:focus, &.active {
    outline: 0.5px solid $weak-grey;
    background: $weak-grey;
  }
}

.ternary {
  color: $standard-grey;
  background: $weak-grey;
  border: none;
  outline: 0.5px solid $message-gray;
  box-shadow: none;
  transition: all 0.25s ease-out;

  &:hover {
    color: $standard-grey;
    background: $message-gray;
    box-shadow: none;
    outline: 0.5px solid $idle-gray;
  }

  &:active, &:focus, &.active {
    color: $white;
    background: $active-grey;
    box-shadow: none;
    outline: none;
  }
}

.dropdownSecondary {
  color: $idle-gray;
  background: $white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  border: none;
  transition: all 0.25s ease-out;

  &:hover {
    color: $standard-grey;
    background: $weak-grey;
    box-shadow: none;
    outline: 0.5px solid $message-gray;
  }

  &:active, &:focus, &.active {
    color: $white;
    background: $active-grey;
    box-shadow: none;
    outline: none;
  }
}

.transparent {
  background: none;
  color: $standard-grey;
  transition: all 0.25s ease-out;

  &:hover {
    background: $accent-gray;
    outline: 0.5px solid $weak-grey;
  }

  &:active, &:focus, &.active {
    background: $weak-grey;
    outline: 0.5px solid $weak-grey;
  }
}

.secondaryTransparent {
  background: none;
  box-shadow: none;
  border: none;
  color: $black-grey;
  transition: all 0.25s ease-out;

  &:hover {
    color: $standard-grey;
    background: $accent-gray;
    outline: 0.5px solid $message-gray;
  }

  &:active, &:focus, &.active {
    color: $standard-grey;
    background: $weak-grey;
    outline: 0.5px solid $idle-gray;
  }
}

.iconPill {
  width: 1.5rem;
  height: 1.5rem;
  color: $standard-grey;
  background: $white;
  outline: 0.5px solid $weak-grey;
  transition: all 0.25s ease-out;

  &:hover {
    background: $faded-grey;
    outline: 0.5px solid $weak-grey;
  }

  &:active, &:focus, &.active {
    background: $weak-grey;
    outline: 0.5px solid $weak-grey;
  }

  &Icon {
    font-size: 0.75rem;
    line-height: 0.75rem;
    font-weight: 300;
  }
}

.disabled {
  background-color: $accent-gray;
  outline: 0.5px solid $weak-grey;
  color: $weak-grey;
  box-shadow: none;
  filter: none;

  &:hover, &:active, &:focus, &.active {
    background-color: $accent-gray;
    outline: 0.5px solid $weak-grey;
    color: $weak-grey;
    box-shadow: none;
    cursor: default;
  }

  &.ternary {
    outline: 0.5px solid $message-gray;
    background: $weak-grey;
    color: $message-gray;
  }

  &.transparent {
    background: none;
    outline: none;
    color: $message-gray;
  }
}
